





















































import Vue from "vue";
export default Vue.extend({
  name: "App",
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    $route(to, from) {
      this.isActive = false;
      console.log(to, from);
    },
  },
});
